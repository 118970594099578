<template>
  <v-radio-group v-model="selectVal" inline class="custome-radio-group">
    <div class="radio-part" :class="{ 'custome-direction-spm': isCustomDirectionSmp, 'custome-direction': isCustomDirection}">
      <div v-for="(item, i) in items" :key="i" class="radio-item">
        <v-radio :label="`${item.name}`" :value="item.id" :ripple="false" />
        <Tooltip v-if="item.tooltip" :tooltipText="item.tooltip" />
      </div>
    </div>
  </v-radio-group>
</template>

<script>
import Tooltip from '@/components/commonApp/Tooltip';
export default {
  name: 'RadioCustom',
  components: { Tooltip },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: [Number, String],
      default: 0,
    },
    isCustomDirection: {
      type: Boolean,
      default: false
    },
    isCustomDirectionSmp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: 0,
    };
  },
  methods: {
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
  },
  computed: {
    selectVal: {
      get() {
        return this.defaultValue;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custome-radio-group {
  ::v-deep {
    .v-input__control {
      .v-input__slot {
        .v-radio {
          .v-input--selection-controls__input {
            margin-top: 5px !important;
          }
        }
        .v-input--radio-group__input {
          .radio-part {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            gap: 8px;
            .v-radio {
              margin: 0;
              .v-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.42px;
                color: $monoBlack;
                // padding-bottom: 2px;
              }
            }
            &.custome-direction-spm {
              flex-direction: column;
            }
            &.custome-direction {
              flex-direction: column;
            }
          }

          .radio-item {
            display: flex;
            align-items: center;
            gap: 6px;
            .question {
              display: flex;
              z-index: 100;
            }
            .t-label {
              font-family: "Source Han Sans JP";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.42px;
              color: $black;
            }
            .v-radio {
              align-items: flex-start;
            }
          }
        }
      }
      .v-messages {
        display: none;
      }
    }
  }
}

@include desktop {
  .custome-radio-group {
    ::v-deep {
      .v-input__control {
        .v-input__slot {
          .v-input--radio-group__input {
            .radio-part {
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              gap: 16px;
              &.custome-direction-spm {
                flex-direction: row;
              }
            }
          }
        }
      }
    }
  }
}
</style>
